// Custom Bootstrap
// Custom variables, overwrites Bootstrap ones!
$gutta-red: #ce1d2f;
$gutta-green: #73c23a;
$font-size-base: 0.8rem !default;
$h1-font-size: $font-size-base * 1.75 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.25 !default;
$h4-font-size: $font-size-base !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;
$primary: $gutta-green !default;

.modal-content * , .card * {
  min-height: .01px;
}

.calc-material,
.calc-profile,
.calc-sort {
  cursor: pointer;
}

.existing-roof-block {
  min-height: 100px !important;
}


.bg-green {
  background: #69b022;
}

.bg-green-gradient {
  background-image: linear-gradient(to bottom, #91c53c 0%, #27853b 100%);
  background-repeat: repeat-x;
}

.logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    width: 115px;
}

// Include all of Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";
